@charset "UTF-8";
/*! normalize-scss | MIT/GPLv2 License | bit.ly/normalize-scss */
/* Document
       ========================================================================== */
/**
     * 1. Change the default font family in all browsers (opinionated).
     * 2. Correct the line height in all browsers.
     * 3. Prevent adjustments of font size after orientation changes in
     *    IE on Windows Phone and in iOS.
     */
html {
  font-family: sans-serif;
  /* 1 */
  line-height: 1.15;
  /* 2 */
  -ms-text-size-adjust: 100%;
  /* 3 */
  -webkit-text-size-adjust: 100%;
  /* 3 */ }

/* Sections
       ========================================================================== */
/**
     * Remove the margin in all browsers (opinionated).
     */
body {
  margin: 0; }

/**
     * Add the correct display in IE 9-.
     */
article,
aside,
footer,
header,
nav,
section {
  display: block; }

/**
     * Correct the font size and margin on `h1` elements within `section` and
     * `article` contexts in Chrome, Firefox, and Safari.
     */
h1 {
  font-size: 2em;
  margin: 0.67em 0; }

/* Grouping content
       ========================================================================== */
/**
     * Add the correct display in IE 9-.
     */
figcaption,
figure {
  display: block; }

/**
     * Add the correct margin in IE 8.
     */
figure {
  margin: 1em 40px; }

/**
     * 1. Add the correct box sizing in Firefox.
     * 2. Show the overflow in Edge and IE.
     */
hr {
  box-sizing: content-box;
  /* 1 */
  height: 0;
  /* 1 */
  overflow: visible;
  /* 2 */ }

/**
     * Add the correct display in IE.
     */
main {
  display: block; }

/**
     * 1. Correct the inheritance and scaling of font size in all browsers.
     * 2. Correct the odd `em` font sizing in all browsers.
     */
pre {
  font-family: monospace, monospace;
  /* 1 */
  font-size: 1em;
  /* 2 */ }

/* Links
       ========================================================================== */
/**
     * 1. Remove the gray background on active links in IE 10.
     * 2. Remove gaps in links underline in iOS 8+ and Safari 8+.
     */
a {
  background-color: transparent;
  /* 1 */
  -webkit-text-decoration-skip: objects;
  /* 2 */ }

/**
     * Remove the outline on focused links when they are also active or hovered
     * in all browsers (opinionated).
     */
a:active,
a:hover {
  outline-width: 0; }

/* Text-level semantics
       ========================================================================== */
/**
     * 1. Remove the bottom border in Firefox 39-.
     * 2. Add the correct text decoration in Chrome, Edge, IE, Opera, and Safari.
     */
abbr[title] {
  border-bottom: none;
  /* 1 */
  text-decoration: underline;
  /* 2 */
  text-decoration: underline dotted;
  /* 2 */ }

/**
     * Prevent the duplicate application of `bolder` by the next rule in Safari 6.
     */
b,
strong {
  font-weight: inherit; }

/**
     * Add the correct font weight in Chrome, Edge, and Safari.
     */
b,
strong {
  font-weight: bolder; }

/**
     * 1. Correct the inheritance and scaling of font size in all browsers.
     * 2. Correct the odd `em` font sizing in all browsers.
     */
code,
kbd,
samp {
  font-family: monospace, monospace;
  /* 1 */
  font-size: 1em;
  /* 2 */ }

/**
     * Add the correct font style in Android 4.3-.
     */
dfn {
  font-style: italic; }

/**
     * Add the correct background and color in IE 9-.
     */
mark {
  background-color: #ff0;
  color: #000; }

/**
     * Add the correct font size in all browsers.
     */
small {
  font-size: 80%; }

/**
     * Prevent `sub` and `sup` elements from affecting the line height in
     * all browsers.
     */
sub,
sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline; }

sub {
  bottom: -0.25em; }

sup {
  top: -0.5em; }

/* Embedded content
       ========================================================================== */
/**
     * Add the correct display in IE 9-.
     */
audio,
video {
  display: inline-block; }

/**
     * Add the correct display in iOS 4-7.
     */
audio:not([controls]) {
  display: none;
  height: 0; }

/**
     * Remove the border on images inside links in IE 10-.
     */
img {
  border-style: none; }

/**
     * Hide the overflow in IE.
     */
svg:not(:root) {
  overflow: hidden; }

/* Forms
       ========================================================================== */
/**
     * 1. Change the font styles in all browsers (opinionated).
     * 2. Remove the margin in Firefox and Safari.
     */
button,
input,
optgroup,
select,
textarea {
  font-family: sans-serif;
  /* 1 */
  font-size: 100%;
  /* 1 */
  line-height: 1.15;
  /* 1 */
  margin: 0;
  /* 2 */ }

/**
     * Show the overflow in IE.
     */
button {
  overflow: visible; }

/**
     * Remove the inheritance of text transform in Edge, Firefox, and IE.
     * 1. Remove the inheritance of text transform in Firefox.
     */
button,
select {
  /* 1 */
  text-transform: none; }

/**
     * 1. Prevent a WebKit bug where (2) destroys native `audio` and `video`
     *    controls in Android 4.
     * 2. Correct the inability to style clickable types in iOS and Safari.
     */
button,
html [type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button;
  /* 2 */ }

button,
[type="button"],
[type="reset"],
[type="submit"] {
  /**
       * Remove the inner border and padding in Firefox.
       */
  /**
       * Restore the focus styles unset by the previous rule.
       */ }
  button::-moz-focus-inner,
  [type="button"]::-moz-focus-inner,
  [type="reset"]::-moz-focus-inner,
  [type="submit"]::-moz-focus-inner {
    border-style: none;
    padding: 0; }
  button:-moz-focusring,
  [type="button"]:-moz-focusring,
  [type="reset"]:-moz-focusring,
  [type="submit"]:-moz-focusring {
    outline: 1px dotted ButtonText; }

/**
     * Show the overflow in Edge.
     */
input {
  overflow: visible; }

/**
     * 1. Add the correct box sizing in IE 10-.
     * 2. Remove the padding in IE 10-.
     */
[type="checkbox"],
[type="radio"] {
  box-sizing: border-box;
  /* 1 */
  padding: 0;
  /* 2 */ }

/**
     * Correct the cursor style of increment and decrement buttons in Chrome.
     */
[type="number"]::-webkit-inner-spin-button,
[type="number"]::-webkit-outer-spin-button {
  height: auto; }

/**
     * 1. Correct the odd appearance in Chrome and Safari.
     * 2. Correct the outline style in Safari.
     */
[type="search"] {
  -webkit-appearance: textfield;
  /* 1 */
  outline-offset: -2px;
  /* 2 */
  /**
       * Remove the inner padding and cancel buttons in Chrome and Safari on macOS.
       */ }
  [type="search"]::-webkit-search-cancel-button, [type="search"]::-webkit-search-decoration {
    -webkit-appearance: none; }

/**
     * 1. Correct the inability to style clickable types in iOS and Safari.
     * 2. Change font properties to `inherit` in Safari.
     */
::-webkit-file-upload-button {
  -webkit-appearance: button;
  /* 1 */
  font: inherit;
  /* 2 */ }

/**
     * Change the border, margin, and padding in all browsers (opinionated).
     */
fieldset {
  border: 1px solid #c0c0c0;
  margin: 0 2px;
  padding: 0.35em 0.625em 0.75em; }

/**
     * 1. Correct the text wrapping in Edge and IE.
     * 2. Correct the color inheritance from `fieldset` elements in IE.
     * 3. Remove the padding so developers are not caught out when they zero out
     *    `fieldset` elements in all browsers.
     */
legend {
  box-sizing: border-box;
  /* 1 */
  display: table;
  /* 1 */
  max-width: 100%;
  /* 1 */
  padding: 0;
  /* 3 */
  color: inherit;
  /* 2 */
  white-space: normal;
  /* 1 */ }

/**
     * 1. Add the correct display in IE 9-.
     * 2. Add the correct vertical alignment in Chrome, Firefox, and Opera.
     */
progress {
  display: inline-block;
  /* 1 */
  vertical-align: baseline;
  /* 2 */ }

/**
     * Remove the default vertical scrollbar in IE.
     */
textarea {
  overflow: auto; }

/* Interactive
       ========================================================================== */
/*
     * Add the correct display in Edge, IE, and Firefox.
     */
details {
  display: block; }

/*
     * Add the correct display in all browsers.
     */
summary {
  display: list-item; }

/*
     * Add the correct display in IE 9-.
     */
menu {
  display: block; }

/* Scripting
       ========================================================================== */
/**
     * Add the correct display in IE 9-.
     */
canvas {
  display: inline-block; }

/**
     * Add the correct display in IE.
     */
template {
  display: none; }

/* Hidden
       ========================================================================== */
/**
     * Add the correct display in IE 10-.
     */
[hidden] {
  display: none; }

html {
  font-size: 62.5%;
  box-sizing: border-box; }
  html *,
  html ::before,
  html ::after {
    box-sizing: inherit; }

body,
input,
textarea,
button,
select {
  font-family: -apple-system, BlinkMacSystemFont, sans-serif; }

input[type="text"],
input[type="color"] {
  padding: 0.3rem;
  border: 1px solid rgba(255, 255, 255, 0.3);
  border-radius: 0.3rem;
  box-shadow: inset 1px 1px 2px rgba(0, 0, 0, 0.3);
  background: transparent;
  color: #fff;
  appearance: none; }
  input[type="text"]:focus,
  input[type="color"]:focus {
    border-color: rgba(255, 255, 255, 0.6);
    outline: none; }

input[type="color"]::-webkit-color-swatch-wrapper {
  padding: 0; }

input[type="color"]::-webkit-color-swatch {
  width: auto;
  border: 0; }

body {
  display: flex;
  flex-direction: column;
  height: 100vh;
  background-color: #181b20;
  color: #fff;
  font-size: 1.6rem;
  overflow-x: hidden; }

.project-header {
  display: flex;
  position: relative;
  flex-shrink: 0;
  align-items: center;
  padding: 0.5rem;
  box-shadow: 0 2px 0 rgba(0, 0, 0, 0.2);
  z-index: 2; }
  .project-header h1,
  .project-header p {
    margin: 0 1.2rem; }
  .project-header h1 {
    padding-bottom: 0.4rem; }

.button {
  display: flex;
  position: relative;
  align-items: center;
  justify-content: center;
  margin: 0 0.3rem;
  padding: 1rem;
  box-shadow: 2px 2px 0 rgba(0, 0, 0, 0.2);
  text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.3); }
  .button.is-active {
    z-index: 3; }

.button-group {
  display: flex;
  flex-shrink: 0;
  margin: 0 -0.4rem; }
  .button-group .flex-spacer {
    flex-grow: 1; }

.button-project h2 {
  margin: 0;
  font-size: 1.6rem; }

.button-project .edit-link {
  display: none;
  position: absolute;
  top: 100%;
  right: 0;
  padding: 0.3rem;
  background: rgba(0, 0, 0, 0.7);
  color: #fff;
  font-size: 1.2rem;
  text-decoration: none; }

.button-project:hover .edit-link {
  display: block; }

.button-project.is-editing::after {
  content: '*'; }

.button-add {
  box-shadow: none;
  color: #fff;
  text-decoration: none; }

.entry-list {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  flex-shrink: 1;
  flex-wrap: nowrap;
  margin: 0;
  padding: 2rem 0 2rem 2.4rem;
  overflow-y: auto; }
  .entry-list > li {
    display: flex;
    flex: 0 0 auto;
    align-items: flex-start;
    justify-content: flex-start;
    min-height: 0.1rem;
    margin: 0;
    border-top: 0.2rem solid currentColor;
    list-style: none; }
    .entry-list > li:hover {
      z-index: 2; }
    .entry-list > li.is-committed {
      position: relative;
      border-top: 0.1rem solid currentColor;
      opacity: 0.3; }
      .entry-list > li.is-committed .entry-handle {
        display: none; }

.entry-handle {
  width: 0.2rem;
  height: 0.2rem;
  margin: -0.2rem 0 0 -0.8rem;
  border: 0.1rem solid;
  box-shadow: 2px 2px 0 rgba(0, 0, 0, 0.2), inset 2px 2px 0 rgba(0, 0, 0, 0.2);
  background: #181b20;
  content: ''; }
  li:hover .entry-handle,
  li.is-staged .entry-handle {
    width: 0.8rem;
    height: 0.8rem;
    margin-left: -1.4rem; }

.entry-description {
  display: block;
  position: relative;
  margin: -1.2rem 0 0 0.3rem;
  padding: 0.3rem;
  background: rgba(0, 0, 0, 0.7);
  color: #fff;
  font-size: 1.2rem;
  opacity: 0;
  z-index: 2;
  pointer-events: none; }
  .entry-list > li:not(.is-committed):hover .entry-description {
    opacity: 1;
    pointer-events: auto; }
  .entry-description::before {
    display: block;
    position: absolute;
    top: 0;
    right: 100%;
    bottom: 0;
    width: 0.4rem;
    content: ''; }

.entry-time-start {
  width: 6rem; }

.entry-project {
  width: 7rem; }

.stage {
  display: none;
  position: absolute;
  top: 0;
  right: 0;
  width: 30rem;
  padding: 1rem;
  background: rgba(0, 0, 0, 0.7);
  z-index: 4; }
  .stage.has-entries {
    display: block; }

.staged-entries {
  display: flex;
  flex-wrap: wrap;
  padding: 0;
  list-style: none; }
  .staged-entries > li {
    margin: 0 0.4rem 0.4rem 0;
    padding: 0.2rem 0.5rem; }
  .staged-entries .entry-id {
    margin: 0.3rem 0 0;
    font-size: 1em; }
  .staged-entries .entry-info {
    font-size: 1.1rem; }
  .staged-entries .entry-project {
    text-transform: uppercase; }
  .staged-entries .sep::before {
    margin: 0 0.6em;
    content: '·'; }

.project-editor {
  display: none;
  margin: 1rem 0;
  padding: 1rem 0;
  border: 1px solid rgba(0, 0, 0, 0.4);
  border-right: 0;
  border-left: 0; }
  .project-editor.is-active {
    display: block; }
  .project-editor form {
    display: flex; }
  .project-editor label,
  .project-editor button {
    margin: 0.9rem; }
  .project-editor label {
    display: flex;
    align-items: center; }
    .project-editor label input {
      margin-left: 0.4rem; }
  .project-editor .button-save {
    border: 0.2rem solid #fff;
    background-color: #fff;
    color: #181b20; }
  .project-editor .button-cancel {
    border: 0.2rem solid #fff;
    background-color: transparent;
    color: #fff; }
