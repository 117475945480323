@import '../../node_modules/normalize-scss/sass/normalize';
@include normalize;

$color-background: hsl(219, 14%, 11%);
$white: #fff;
$black: #000;

html {
	font-size: 62.5%;
	box-sizing: border-box;

	*,
	::before,
	::after {
		box-sizing: inherit;
	}
}

body,
input,
textarea,
button,
select {
	font-family: -apple-system, BlinkMacSystemFont, sans-serif;
}


input[type="text"],
input[type="color"] {
	padding: 0.3rem;
	border: 1px solid rgba($white, 0.3);
	border-radius: 0.3rem;
	box-shadow: inset 1px 1px 2px rgba($black, 0.3);
	background: transparent;
	color: $white;
	appearance: none;

	&:focus {
		border-color: rgba($white, 0.6);
		outline: none;
	}
}

input[type="color"] {

	&::-webkit-color-swatch-wrapper {
		padding: 0;
	}

	&::-webkit-color-swatch {
		width: auto;
		border: 0;
	}
}

body {
	display: flex;
	flex-direction: column;
	height: 100vh;
	background-color: $color-background;
	color: $white;
	font-size: 1.6rem;
	overflow-x: hidden;
	// Prevent negative margin from causing horizontal scrolling.
}

.project-header {
	display: flex;
	position: relative;
	flex-shrink: 0;
	align-items: center;
	padding: 0.5rem;
	box-shadow: 0 2px 0 rgba($black, 0.2);
	z-index: 2;

	h1,
	p {
		margin: 0 1.2rem;
	}

	h1 {
		// Compensate for the fact that most project names have only lower-case
		// letters.
		padding-bottom: 0.4rem;
	}
}

.button {
	display: flex;
	position: relative;
	align-items: center;
	justify-content: center;
	margin: 0 0.3rem;
	padding: 1rem;
	box-shadow: 2px 2px 0 rgba($black, 0.2);
	text-shadow: 1px 1px 1px rgba($black, 0.3);

	&.is-active {
		z-index: 3;
	}
}

.button-group {
	display: flex;
	flex-shrink: 0;
	margin: 0 -0.4rem;

	.flex-spacer {
		flex-grow: 1;
	}
}

.button-project {

	h2 {
		margin: 0;
		font-size: 1.6rem;
	}

	.edit-link {
		display: none;
		position: absolute;
		top: 100%;
		right: 0;
		padding: 0.3rem;
		background: rgba($black, 0.7);
		color: $white;
		font-size: 1.2rem;
		text-decoration: none;
	}

	&:hover .edit-link {
		display: block;
	}

	&.is-editing::after {
		content: '*';
	}
}

.button-add {
	box-shadow: none;
	color: $white;
	text-decoration: none;
}

.entry-list {
	display: flex;
	flex-direction: column;
	flex-grow: 1;
	flex-shrink: 1;
	flex-wrap: nowrap;
	margin: 0;
	padding: 2rem 0 2rem 2.4rem;
	overflow-y: auto;

	> li {
		display: flex;
		flex: 0 0 auto;
		align-items: flex-start;
		justify-content: flex-start;
		min-height: 0.1rem;
		margin: 0;
		border-top: 0.2rem solid currentColor;
		list-style: none;

		&:hover {
			z-index: 2;
		}

		&.is-committed {
			position: relative;
			border-top: 0.1rem solid currentColor;
			opacity: 0.3;

			.entry-handle {
				display: none;
			}
		}
	}
}

.entry-handle {
	width: 0.2rem;
	height: 0.2rem;
	margin: -0.2rem 0 0 -0.8rem;
	border: 0.1rem solid;
	box-shadow: 2px 2px 0 rgba($black, 0.2), inset 2px 2px 0 rgba($black, 0.2);
	background: $color-background;
	content: '';

	li:hover &,
	li.is-staged & {
		width: 0.8rem;
		height: 0.8rem;
		margin-left: -1.4rem;
	}
}

.entry-description {
	display: block;
	position: relative;
	margin: -1.2rem 0 0 0.3rem;
	padding: 0.3rem;
	background: rgba($black, 0.7);
	color: $white;
	font-size: 1.2rem;
	opacity: 0;
	z-index: 2;
	pointer-events: none;

	.entry-list > li:not(.is-committed):hover & {
		opacity: 1;
		pointer-events: auto;
	}

	// Add a transparent spacer between .entry-handle and .entry-description so
	// that hover status isn't interrupted.
	&::before {
		display: block;
		position: absolute;
		top: 0;
		right: 100%;
		bottom: 0;
		width: 0.4rem;
		content: '';
	}
}

.entry-time-start {
	width: 6rem;
}

.entry-project {
	width: 7rem;
}

.stage {
	display: none;
	position: absolute;
	top: 0;
	right: 0;
	width: 30rem;
	padding: 1rem;
	background: rgba($black, 0.7);
	z-index: 4; // Above project header + buttons.

	&.has-entries {
		display: block;
	}
}

.staged-entries {
	display: flex;
	flex-wrap: wrap;
	padding: 0;
	list-style: none;

	> li {
		margin: 0 0.4rem 0.4rem 0;
		padding: 0.2rem 0.5rem;
	}

	.entry-id {
		margin: 0.3rem 0 0;
		font-size: 1em;
	}

	.entry-info {
		font-size: 1.1rem;
	}

	.entry-project {
		text-transform: uppercase;
	}

	.sep {

		&::before {
			margin: 0 0.6em;
			content: '·';
		}
	}
}

.project-editor {
	display: none;
	margin: 1rem 0;
	padding: 1rem 0;
	border: 1px solid rgba($black, 0.4);
	border-right: 0;
	border-left: 0;

	&.is-active {
		display: block;
	}

	form {
		display: flex;
	}

	label,
	button {
		margin: 0.9rem;
	}

	label {
		display: flex;
		align-items: center;

		input {
			margin-left: 0.4rem;
		}
	}

	.button-save {
		border: 0.2rem solid $white;
		background-color: $white;
		color: $color-background;
	}

	.button-cancel {
		border: 0.2rem solid $white;
		background-color: transparent;
		color: $white;
	}
}
